import React, { Component } from 'react'
import { Formik } from 'formik';
import {injectIntl, FormattedMessage} from 'react-intl';
import fetch from 'isomorphic-unfetch';
import styled from '@emotion/styled'

import Button from '../Button'
import sendEvent from '../../../helpers/sendAnalytics'

const FooterFormWrapper = styled.div`
  ${tw`flex flex-wrap`}
  margin-right: -10px;
  margin-bottom: 20px;
  margin-left: -10px;
`

const FooterFormLeft = styled.div`
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;

  @media (max-width: 1280px) {
    width: 100%;
    margin-bottom: 20px;
  }
`

const FooterFormRight = styled.div`
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;

  @media (max-width: 1280px) {
    width: 100%;
  }
`

const FooterFormFull = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
`

const FooterFormCheckbox = styled.input`
`

const FooterFormCheckboxLabel = styled.label`
  ${tw`font-raleway text-xs text-black leading-tight`}
  margin-left: 10px;
`

const Label = styled.label`
  ${tw`font-raleway text-base text-black block w-full`}

  input {
    margin-top: 10px;
  }
`

const FormButtonWrapper = styled.div`
  ${tw`mt-5`}
`

const ThanksMessage = styled.div`
  ${tw`font-raleway text-xs text-black leading-tight mt-5`}
`

class FooterSubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      loading: false
    }
  }

  render() {
    const { submitted, loading } = this.state;
    const { intl } = this.props;

    if (submitted) {
      return (<ThanksMessage><FormattedMessage id="thank_you_subscribe" /></ThanksMessage>)
    }

    return (
      <Formik
        initialValues={{ firstname: '', lastname: '', email: '' }}
        validate={values => {
          const errors = {}

          if (!values.firstname) {
            errors.firstname = 'Required'
          }

          if (!values.lastname) {
            errors.lastname = 'Required'
          }

          if (!values.email) {
            errors.email = 'Required'
          }

          return errors
        }}
        onSubmit={(values) => {
          const { firstname, lastname, email } = values

          this.setState({
            loading: true
          });

          const graphQlServer = `https://www.hawkemailsolutions.ca/api/create/email?firstname=${firstname}&lastname=${lastname}&email=${email}&brand=royale&language=${intl.locale}`

          fetch(graphQlServer, {
            headers: {
              'content-type': 'application/json',
              'accept': 'application/json'
            },
            method: 'GET'
          })
            .then(res => res.json())
            .then(() => {
              sendEvent({
                ga: {
                  category: 'Email_Subscribe',
                  action: 'Click',
                  label: (intl.locale === 'en') ? 'Website_Email_Signup' : 'Website_Email_Signup_FR'
                },
                gtag: {send_to: 'AW-951001886/dGoFCI-ql_0YEJ7GvMUD'}
              })

              sendEvent({
                gtag: {send_to: 'GTM-5SVFSFGH/formsubmit'}
              })

              this.setState({
                submitted: true
              })
            })
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <FooterFormWrapper>
              <FooterFormLeft>
                <Label htmlFor="firstname">
                  <FormattedMessage id='footer.first_name' />
                  <input
                    aria-labelledby="firstname"
                    type="text"
                    maxLength="256"
                    name="firstname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstname}
                    id="firstname"
                    autoComplete="given-name"
                    required
                  />
                </Label>
              </FooterFormLeft>
              <FooterFormRight>
                <Label htmlFor="lastname">
                  <FormattedMessage id='footer.last_name' />
                  <input
                    aria-labelledby="lastname"
                    type="text"
                    maxLength="256"
                    name="lastname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastname}
                    id="lastname"
                    autoComplete="family-name"
                    required
                  />
                </Label>
              </FooterFormRight>
            </FooterFormWrapper>
            <FooterFormWrapper>
              <FooterFormFull>
                <Label htmlFor="email">
                  <FormattedMessage id='footer.email_address' />
                  <input
                    aria-labelledby="email"
                    type="email"
                    maxLength="256"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    id="email"
                    autoComplete="email"
                    required
                  />
                </Label>
              </FooterFormFull>
            </FooterFormWrapper>

            <FooterFormCheckbox
              type="checkbox"
              id="checkbox"
              name="checkbox"
              aria-labelledby="checkbox"
              required
            />
            <FooterFormCheckboxLabel
              htmlFor="checkbox"
            >
              <FormattedMessage id='footer.optin'>
                {(txt) => (
                  <span dangerouslySetInnerHTML={{__html:txt}} />
                )}
              </FormattedMessage>
            </FooterFormCheckboxLabel>

            <FormButtonWrapper>
              <Button type="submit" aria-label="submit" loading={loading}>
                <FormattedMessage id='footer.subscribe' />
              </Button>
            </FormButtonWrapper>
          </form>
        )}
      </Formik>
    )
  }
}

export default injectIntl(FooterSubscribe)
