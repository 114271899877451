import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import styled from '@emotion/styled'

const BgImageStyles = styled(Image)`
  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  & > picture > img,
  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
    font-family: 'object-fit: ${props => props.fit || 'cover'} !important; object-position: ${props => props.position || '50% 50%'} !important;'
  }
`

const BgImage = (props) => (
  <BgImageStyles
    style={{
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%"
    }}
    {...props}
  />
)

BgImage.propTypes = {
  // Height of the image
  height: PropTypes.string,
  // Background fit (cover, contain)
  fit: PropTypes.string,
  // Background position
  position: PropTypes.string
}

BgImage.defaultProps = {
  height: 'auto',
  fit: 'cover',
  position: '50% 50%'
}

export default BgImage
